import {FC} from "react";

interface Props {
    children: JSX.Element;
}

const PrimaryContainer: FC<Props> = ({children}) => {
    return (
        <>
            <div className={"primary-container"}>
                {children}
            </div>
        </>
    );
};
export default PrimaryContainer;
