import React, {FC, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars} from "@fortawesome/free-solid-svg-icons";
import Logo from "../assets/logo_izida.png";

import BgFlag from "../assets/flags/bg.png";
import EnFlag from "../assets/flags/en.png";

interface Props {
    transparent?: boolean;
}

const Navbar: FC<Props> = ({transparent}) => {
    function handleScrollToElement(event: Event | undefined) {
        if (window.scrollY === 0 && transparent) {
            setShow(false);
        } else {
            setShow(true);
        }
    }

    const [menuOpen, setMenuOpen] = useState(false);
    const [show, setShow] = useState(!transparent);
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const [languagesOpen, setLanguagesOpen] = useState(false);
    const {i18n} = useTranslation();

    useEffect(() => {
        window.addEventListener("scroll", handleScrollToElement);
    });

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    const {t} = useTranslation();

    return (
        <div
            className={
                show
                    ? "bg fixed z-10 w-full flex md:block text-black"
                    : "transparent fixed z-10 w-full flex md:block text-black"
            }
        >
            <div
                className=" container py-4 px-4 md:mx-auto flex flex-wrap md:py-5 md:px-40 flex-col md:flex-row md:items-center">
                <div className="items-center flex">
                    <Link to="/">
                        <img src={Logo} alt="Сладоледи Изида" className="w-2/4"/>
                    </Link>
                </div>
                <nav
                    className={`p-5 md:p-0 md:ml-auto md:flex md:flex-wrap items-center text-sm gap-y-1 md:gap-y-0 md:text-base font-black uppercase justify-center ${
                        menuOpen ? "" : "hidden"
                    }`}
                >
                    <Link to="/" className="mr-5 hover:text-red-900 block">
                        {t("navbar.home")}
                    </Link>
                    <Link to="/products" className="mr-5 hover:text-red-900 block">
                        {t("navbar.iceCreams")}
                    </Link>
                    <Link
                        to="/about"
                        className="relative mr-5 hover:text-red-900 block"
                        onMouseEnter={() => setDropdownOpen(true)}
                        onMouseLeave={() => setDropdownOpen(false)}
                    >
                        {t("navbar.about")}
                        <div
                            className={`absolute z-10 bg-white py-2 w-40 left-0 text-black font-black text-sm ${
                                dropdownOpen ? "block" : "hidden"
                            }`}
                        >
                            <Link to="/products" className="block px-4 py-2 hover:bg-gray-200">
                                {t("navbar.iceCreams")}
                            </Link>
                            <Link to="/about" className="block px-4 py-2 hover:bg-gray-200">
                                {t("navbar.history")}
                            </Link>
                            <Link to="/other" className="block px-4 py-2 hover:bg-gray-200">
                                {t("navbar.otherActivities")}
                            </Link>
                        </div>
                    </Link>
                    <Link to="/contacts" className="mr-5 hover:text-red-900 block">
                        {t("navbar.contacts")}
                    </Link>
                    <div className="relative mr-5">
                        <button
                            className="text-black hover:text-red-900"
                            onClick={() => setLanguagesOpen(!languagesOpen)}
                        >
                            {i18n.language === 'bg' ? (
                                <img src={BgFlag} width={"25"} alt="BG" />
                            ) : (
                                <img src={EnFlag} width={"25"} alt="EN" />
                            )}
                        </button>
                        <div
                            className={`absolute z-10 bg-white py-2 w-40 left-0 text-black font-black text-sm ${
                                languagesOpen ? "block" : "hidden"
                            }`}
                            onMouseLeave={() => {
                                setLanguagesOpen(false)
                            }}
                        >
                            <button
                                key={"en"}
                                className="flex items-center gap-1 px-1 py-2 hover:bg-gray-200"
                                onClick={() => {
                                    changeLanguage("en");
                                    setLanguagesOpen(false);
                                }}
                            >
                                <img src={EnFlag} width="25" alt={"English Langauge"}/> English
                            </button>
                            <button
                                key={"bg"}
                                className="flex items-center gap-1 px-1 py-2 hover:bg-gray-200"
                                onClick={() => {
                                    changeLanguage("bg");
                                    setLanguagesOpen(false);
                                }}
                            >
                                <img src={BgFlag} width="25" alt={"BG"}/> Български език
                            </button>
                        </div>
                    </div>
                </nav>
            </div>
            <div
                className="flex md:hidden my-auto mr-5"
                onClick={() => setMenuOpen(!menuOpen)}
            >
    <span className="text-4xl text-red-900">
      <FontAwesomeIcon icon={faBars}/>
    </span>
            </div>
        </div>
    );
};

export default Navbar;
