import React, {FC} from 'react';
import CustomButton from "./CustomButton";

interface Props {
    text: any,
    secondText?: React.ReactNode,
    image: string,
    title?: any,
    secondTitle?: any;
    year?: any,
    align: any,
    button?: boolean,
    buttonText?: any,
    buttonHref: string,
    removeParallax?: boolean,
}

const RowImageText: FC<Props> = ({
                                     text,
                                     secondText,
                                     image,
                                     title,
                                     secondTitle,
                                     year,
                                     button,
                                     buttonText,
                                     buttonHref,
                                     align,
                                     removeParallax
                                 }) => {
    return (
        <>
            <div className="mx-auto">
                <div className="flex flex-col md:grid md:grid-cols-1 md:grid-cols-2 ">
                    {align === 'left' && (
                        <>
                            <div
                                className={`hidden order-1 md:order-1 relative overflow-hidden w-full h-[300px] md:flex h-screen bg-no-repeat ${removeParallax ? "bg-cover md:h-[700px] " : "bg-fixed bg-contain md:h-[600px] "}`}
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}>
                            </div>
                        </>
                    )}

                    <div className="order-2 md:order-1 ">
                        <article className="lg:py-16 my-auto px-4 pt-6 md:pt-0 space-y-4">
                            <p className="font-black text-2xl">{year}</p>

                            <p className="font-black text-4xl md:text-5xl uppercase">
                                {title}
                            </p>

                            <p className="text-xl text-justify">
                                {text}
                            </p>

                            <p className="font-black text-xl uppercase">
                                {secondTitle}
                            </p>

                            <p className="text-xl text-justify">
                                {secondText}
                            </p>

                            <div className={button ? "" : "hidden"}><CustomButton to={buttonHref} text={buttonText}/>
                            </div>

                        </article>
                        <img
                            alt=""
                            src={image}
                            className="block md:hidden h-full w-full object-cover pt-5"
                        />
                    </div>

                    {align === 'right' && (
                        <>
                            <div
                                className={`hidden order-1 md:order-1 relative overflow-hidden w-full h-[300px] md:flex h-screen bg-no-repeat ${removeParallax ? "bg-cover md:h-[700px] " : "bg-fixed bg-cover md:h-[600px] "}`}
                                style={{
                                    backgroundImage: `url(${image})`,
                                }}>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

export default RowImageText;