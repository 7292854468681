export const oldtime = [
    {
        id: 1,
        title: "Oldtime",
        text: "Това е истинският сладолед, който всички обичаме! Това са продуктите, които нашите деца и българският потребител заслужават. Ние, от „Изида” ги произвеждаме за вас без никакъв компромис по отношение на качеството и вкуса, който остава непроменен вече 20 години. Това е сладоледът, който по-възрастните от нас помнят “от едно време”, преди индустриализацията на производствата. Това е сладоледът, на който по-младите не намират аналог по вкус и по качество - сладолед без добавени растителни мазнини, без изкуствени аромати, без подсладители, без Е-та в рецептата.\n",
        variant1: "Премиум сладоледът от серията OLD TIME се произвежда по наша специална рецепта от продукти, които всеки от нас може да намери в кухнята си в къщи – мляко, кристална захар, яйчен жълтък и сметана.\n" +
            "Продуктовата линия Old Time включва разнообразие от продукти за индивидуална консумация и в опаковки от 750 мл.\n",
        variant2: "Марката е печелила престижната награда “Продукт на годината”  чрез гласуване на потребители.\n",
        variant3: "Премиум сладолед ванилия и какао е опаковка 750 мл.",
        image: "",
    },
    {
        id: 2,
        title: "Oldtime Bandi",
        text: "Сладолед на клечка, сладолед между бисквитки, сладолед\n" +
            "сандвич, за теб, за вас, за цялата банда … това е BANDI. Всеки\n" +
            "ден е различен, всеки ден е нов, опитай, споделяй, забавлявай се\n" +
            "… Нямаш време да похапнеш по време на летния купон … ние\n" +
            "сме помислили за сладолед предложение за твб … грабни Bianco\n" +
            "или Nero … разхлади се и продължи купона с бандата.",
        image: ""
    }
];

export const oldtimeEN = [
    {
        id: 1,
        title: "Oldtime",
        text: "This is the real ice cream that we all love! These are the products that our children and the Bulgarian consumer deserve. We, at Izida, produce them for you without any compromise on quality and taste, which has remained unchanged for 20 years. This is the ice cream that the older generation remembers from the old days, before the industrialization of production. This is the ice cream that the younger generation cannot find an equivalent in taste and quality - ice cream without added vegetable fats, without artificial flavors, without sweeteners, without E-numbers in the recipe.\n",
        variant1: "The premium ice cream from the OLD TIME series is produced according to our special recipe from ingredients that each of us can find in our own kitchens - milk, granulated sugar, egg yolk and cream. \n" +
            "The Old Time product line includes a variety of products for individual consumption and in packaging of 750 ml.",
        variant2: "The brand has won the prestigious award Product of the Year through consumer voting.",
        variant3: "Premium ice cream vanilla and cocoa in a packaging of 750 ml.",
        image: "",
    },
    {
        id: 2,
        title: "Oldtime Bandi",
        text: "Ice cream on a stick, ice cream between cookies, ice cream sandwich, for you, for you all, for the whole gang...this is BANDI. Every day is different, every day is new, try, share, have fun...You don't have time to snack during your summer vacation...we have thought of an ice cream option for you...grab Bianco or Nero...cool off and continue your vacation with the gang.",
        image: ""
    }
]

export const bombastic = [
    {
        id: 1,
        title: "Продуктова марка Изида",
        text: "Ние Ви предлагаме избор от класически вкусове сладолед, в удобни разфасовки и оставаме на Вас избора дали да им се насладите поотделно, да ги комбинирате или гарнирате по свой избор. Сладоледите с марката “Изида” са също и нашето предложение за различни експерименти в кухнята със сладолед, мелби, сладкиши, за сладкарски допълнения или просто за предизвикателство на кулинарното Ви въображение .\n",
        variant1: "Солов вкус ванилия в опаковка от 200 и 400 мл\n",
        variant2: "Солов вкус  какао в опаковка от 200 и 400 мл\n",
        variant3: "Солов вкус  лимон в опаковка от 200 и 400 мл\n",
        variant4: "Солов вкус виолетка в опаковка от 200 и 400 мл\n",
        image: "",
    },
    {
        id: 2,
        title: "Продуктова марка Bombastic",
        text: "Наша запазена марка остава съотношението порция за консумация/цена на продуктите с марката Bombastic. С внимание към детайла за перфектна ежедневна порция сладолед ние разработихме марката Bombastic. При всички продукти с тази марка, перфектното за консумация дневно количество сладолед е поднесено с шоколадова глазура във вафлена фунийка или на клечка. А за ценителите на споделената консумация, разработихме семейна опаковка от 6 порции сладолед, гарниран с класически заливки – какао и малина, на изключително добра цена. За едно незабравимо BOMBAstic PARTY. Сладолед за всеки ден, за едно BOMBA-стичо лято! За добро настроение, за всяка възраст и за всяко предпочитание. На BOMBA-стична цена. Всеки ден - невероятен сладолед на невероятна цена!\n",
        variant1: "Сладолед ванилия, какао, пъпеш или ягода, във вафлена фунийка, или на клечка, обвит в млечно-шоколадова глазура.\n",
        variant2: "Сладолед ванилия и пъпеш, гарниран с какаов или малинов сироп, в опаковка от 800 мл.  \n",
        variant3: "Сладолед с пъпеш",
        variant4: "Сладолед с ягода",
        variant5: "Сладолед на клечка",
        image: "",
    },
    {
        id: 3,
        title: "Продуктова марка Excellence ",
        text: "Excellence – съвършеният сладолед за финал на всяко хранене, за споделяне по време на минутите почивка, когато слушаш музика, или гледаш телевизия, докато четеш книга, по време на следобедната отмора или просто когато се завърнеш у дома след работа или училище.  Ние създадохме този сладолед за вашите съвършени споделени моменти! \n",
        variant1: "Сладолед ванилия или какао с конфитюр от смокини, манго или цени череши, със заливка от дъхав карамел или млечно-шоколадов сироп и поръска от натрошени бисквитки.\n",
        image: "",
    }
];

export const bombasticEN = [
    {
        id: 1,
        title: "Izida Product Brand",
        text: "We offer you a selection of classic ice cream flavors in convenient packaging, and leave it up to you to enjoy them separately, combine them, or garnish them as you wish. The “Izida” ice creams are also our suggestion for different experiments in the kitchen with ice cream, melba, sweets, for confectionery additions or just to challenge your culinary imagination.\n",
        variant1: "Vanilla flavor in packaging of 200 and 400 ml\n",
        variant2: "Cocoa flavor in packaging of 200 and 400 ml\n",
        variant3: "Lemon flavor in packaging of 200 and 400 ml\n",
        variant4: "Violet flavor in packaging of 200 and 400 ml\n",
        image: "",
    },
    {
        id: 2,
        title: "Bombastic Product Brand",
        text: "Our proprietary brand remains the ratio of portion for consumption/price of the Bombastic products. With attention to detail for perfect daily ice cream portion, we developed the Bombastic brand. For all products with this brand, the perfect for consumption daily quantity of ice cream is served with chocolate glaze in a waffle cone or on a stick. And for the lovers of shared consumption, we developed a family pack of 6 portions of ice cream, garnished with classic toppings – cocoa and raspberry, at an extremely good price. For an unforgettable BOMBAstic PARTY. Ice cream for every day, for a BOMBA-stic summer! For good mood, for every age and for every preference. At BOMBA-stic price. Every day - incredible ice cream at an incredible price!\n",
        variant1: "Ice cream vanilla, cocoa, watermelon or strawberry, in a waffle cone, or on a stick, wrapped in milk-chocolate glaze.\n",
        variant2: "Ice cream vanilla and watermelon, garnished with cocoa or raspberry syrup, in packaging of 800 ml. \n",
        variant3: "Watermelon ice cream",
        variant4: "Strawberry ice cream",
        variant5: "Ice cream on a stick",
        image: "",
    },
    {
        id: 3,
        title: "Excellence Product Brand",
        text: "Excellence - the perfect ice cream for the finale of any meal, for sharing during the break minutes when you listen to music, or watch TV, while reading a book, during the afternoon break or just when you return home from work or school. We created this ice cream for your perfect shared moments!\n",
        variant1: "Ice cream vanilla or cocoa with confiture of figs, mango or black cherries, with topping of fragrant caramel or milk-chocolate syrup and sprinkled with crushed biscuits.\n",
        image: "",
    }
];

export const haagendazs = [
    {
        id: 1,
        title: "Въведение за марката Häagen-Dazs",
        text: "Häagen-Dazs е американска марка за сладолед, създадена от Рубен Матюс в Бронкс, Ню Йорк. Рубен Матюс промени света на сладоледа – използвайки само висококачествени и внимателно подбрани съставки, неговият богат, кадифен сладолед създаде своя собствена категория. С три прости аромати – ванилия, шоколад и кафе – през 1961 г. се ражда марката Häagen-Dazs. Философията на марката е проста – най-добрият сладолед се произвежда само от най-добрите съставки на света – от ванилията от Мадагаскар, макадамията от Хавай, до шоколада от Белгия и вярата, че всеки заслужава да изпита удоволствие, което е пълно и истинско, и това ни ръководи  във всичко, което правим. Във времето компанията запазва стриктната си политика да използва само истински и свежи продукти. В сладоледите Häagen-Dazs няма консерванти, изкуствени подсладители и оцветители. През 1961 г. Рубен държи да направи сладолед, с който би се гордял. Днес Häagen-Dazs с чест продължава неговото завещание, споделяйки със света това истинско удоволствие от сладоледено преживяване като никое друго.\n",
        variant1: "MACADAMIA NUT BRITTLE – отдайте се на този луксозен ванилов сладолед, комбиниран с крехки хрупкави парченца от макадамия, обвит в белгийски шоколад – перфектно удоволствие.\n\n",
        variant2: "MANGO & RASPBERRY – нашият сладолед от манго и малини, обвит с глазура от белгийски шоколад е освежаващ и същевременно вкусно кремообразен, идеалната комбинация.\n\n",
        variant3: "VANILLA CARAMEL ALMOND – глазура от истински белгийски шоколад и бадеми, която ще се пропука неусетно, позволявайки на зъбите Ви да се потопят във ванилов сладолед с реки от плътен карамелен сос.\n",
        image: "",
    },
    {
        id: 2,
        title: "НОВО! Haagen Dazs DUO",
        text: "В сладоледите Häagen-Dazs няма консерванти, изкуствени подсладители и оцветители. През 1961 г. Рубен държи да направи сладолед, с който би се гордял. Днес Häagen-Dazs с чест продължава неговото завещание, споделяйки със света това истинско удоволствие от сладоледено преживяване като никое друго.\n" +
            "ИЗИДА е официален ексклузивен дистрибутор на сладоледи ХАГЕНДАЗ за БЪЛГАРИЯ.\n",
        variant1: "DUO BELGIAN CHOCOLATE & VANILLA CRUNCH – Насладете се на двойна доза наслада с белгийски шоколад и ванилия. Два класически вкуса, съвпадение, направено в рая на сладоледа. За да издигнете още повече своето луксозно сладоледено изживяване, ще намерите прекрасен шоколадов сос и шоколадови парченца, защото го заслужавате.\n",
        variant2: "DUO BELGIAN CHOCOLATE & STRAWBERRY CRUNCH –  част от нашата колекция сладолед DUO. Два от любимите ви вкусове, белгийски шоколад, луксозна ягода, хрупкав шоколад, хармонично събрани заедно.\n",
        variant3: "STRAWBERRY CHEESECAKE – отдайте се на нашият сладолед Ягодов чийзкейк с хрупкави парченца бисквитки и разливащи се спирали от ягодов сос, комбиниран с нашия забележителен сладоледен чийзкейк.\n",
        variant4: "MACADAMIA NUT BRITTLE – опитайте този луксозен ванилов сладолед, комбиниран с крехки хрупкави парченца от макадамия – перфектното удоволствие.\n",
        variant5: "PRALINES&CREAM – издигнахме тази комбинация до необикновени нива с луксозен ванилов сладолед, хрупкави парченца пекан и сладки карамелени извивки.\n",
        variant6: "MANGO & RASPBERRY – нашият сладолед от манго и малини е освежаващ и същевременно вкусно кремообразен, идеалната комбинация.\n",
        image: "",
    }
];

export const haagendazsEN = [
    {
        id: 1,
        title: "Introduction to Häagen-Dazs Brand",
        text: "Häagen-Dazs is an American ice cream brand created by Reuben Mattus in the Bronx, New York. Reuben Mattus changed the world of ice cream by using only high-quality and carefully selected ingredients. His rich, velvety ice cream created its own category. With three simple flavors - vanilla, chocolate, and coffee - the Häagen-Dazs brand was born in 1961. The brand's philosophy is simple - the best ice cream is made only from the world's best ingredients, from vanilla from Madagascar, macadamia nuts from Hawaii, to chocolate from Belgium. We believe that everyone deserves to experience pleasure that is full and genuine, and that guides everything we do. Over time, the company maintains its strict policy of using only real and fresh products. There are no preservatives, artificial sweeteners, or coloring in Häagen-Dazs ice creams. In 1961, Reuben insisted on making ice cream that he would be proud of. Today, Häagen-Dazs proudly continues his legacy, sharing with the world this true pleasure of an ice cream experience like no other.\n",
        variant1: "MACADAMIA NUT BRITTLE - indulge in this luxurious vanilla ice cream combined with crispy crunchy pieces of macadamia, wrapped in Belgian chocolate - a perfect pleasure.\n\n",
        variant2: "MANGO & RASPBERRY - our mango and raspberry ice cream, wrapped in Belgian chocolate glaze, is refreshing and deliciously creamy, the perfect combination.\n\n",
        variant3: "VANILLA CARAMEL ALMOND - real Belgian chocolate glaze and almonds that will crack unnoticed, allowing your teeth to sink into vanilla ice cream with rivers of dense caramel sauce.\n",
        image: "",
    },
    {
        id: 2,
        title: "NEW! Haagen Dazs DUO",
        text: "There are no preservatives, artificial sweeteners, or coloring in Häagen-Dazs ice creams. In 1961, Reuben insisted on making ice cream that he would be proud of. Today, Häagen-Dazs proudly continues his legacy, sharing with the world this true pleasure of an ice cream experience like no other.\n" +
            "IZIDA is the official exclusive distributor of Häagen-Dazs ice creams for BULGARIA.\n",
        variant1: "DUO BELGIAN CHOCOLATE & VANILLA CRUNCH - enjoy a double dose of delight with Belgian chocolate and vanilla. Two classic flavors, a coincidence made in ice cream heaven. To elevate your luxury ice cream experience even further, you will find a wonderful chocolate sauce and chocolate chips, because you deserve it.\n",
        variant2: "DUO BELGIAN CHOCOLATE & STRAWBERRY CRUNCH - part of our DUO ice cream collection. Two of your favorite flavors, Belgian chocolate, luxurious strawberry, crispy chocolate, harmoniously brought together.\n",
        variant3: "STRAWBERRY CHEESECAKE - indulge in our Strawberry Cheesecake ice cream with crispy biscuit pieces and swirling strawberry sauce, combined with our remarkable ice cream cheesecake.\n",
        variant4: "MACADAMIA NUT BRITTLE - try this luxurious vanilla ice cream combined with crispy crunchy pieces of macadamia - the perfect pleasure.\n",
        variant5: "PRALINES & CREAM - we have raised this combination to unusual levels with luxurious"
    }
];
