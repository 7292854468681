export const distributors = [
    {
        id: 1,
        city: "Бургас",
        company: "ИЗИДА 13 ЕООД",
        phone: "0876 131 010",
        name: "Артин Стамболян",
        email: "burgas@izida.bg"
    },
    {
        id: 2,
        city: "БЛАГОЕВГРАД",
        company: "“БОНИ-91-КП” ЕТ",
        phone: "0885 939 944",
        name: "Костадин Попов",
        email: "boni@izida.bg"
    },
    {
        id: 3,
        city: "ВАРНА",
        company: "“ИЗИДА-КУТЛЕВ” ЕООД\n",
        phone: "0888 923 187",
        name: "Кирил Кутлев",
        email: "kutlev@izida.bg"
    },
    {
        id: 4,
        city: "ВЕЛИКО ТЪРНОВО",
        company: "“ИЗИДА” ООД\n",
        phone: "0879 805 703",
        name: "Мариаяна Йорданова",
        email: "ruse@izida.bg"
    },
    {
        id: 5,
        city: "ДОБРИЧ",
        company: "“ИЗИДА” ООД\n",
        phone: "0885 523 327",
        name: "Антон Фотев",
        email: "dobrich@izida.bg"
    },
    {
        id: 6,
        city: "ПЛЕВЕН",
        company: "“ИЗИДА” ООД\n",
        phone: "0879 905 252",
        name: "Таня Газдова",
        email: "t.gazdova@izida.bg"
    },
    {
        id: 7,
        city: "ПЛОВДИВ",
        company: "“ИЗИДА 13” ЕООД",
        phone: "0876 131 010, 0339 62145",
        name: "Артин Стамболян",
        email: "plovdiv@izida.bg"
    },
    {
        id: 8,
        city: "РУСЕ",
        company: "\"ИЗИДА\" ООД\n",
        phone: "0879 805 703",
        name: "Мариаяна Йорданова",
        email: "ruse@izida.bg"
    },
    {
        id: 9,
        city: "САНДАНСКИ",
        company: "“БОНИ-91-КП” ЕТ",
        phone: "0885 939 944",
        name: "Костадин Попов",
        email: "boni@izida.bg"
    },
    {
        id: 10,
        city: "СИЛИСТРА",
        company: "“ЕЛЕГАНТ 90” ЕТ",
        phone: "0887 599 346",
        name: "Петър Маринов",
        email: "elegant@izida.bg"
    },
    {
        id: 11,
        city: "СИЛИСТРА",
        company: "“СКРЕЖКО” ЕТ",
        phone: "0888 641 890",
        name: "Васил Маринов",
        email: "skrejko@izida.bg"
    },
    {
        id: 12,
        city: "СОФИЯ",
        company: "“ИЗИДА” ООД\n",
        phone: "0878 773 677",
        name: "Ивета Михайлова",
        email: "i.mihaylova@izida.bg"
    },
    {
        id: 13,
        city: "СТАРА ЗАГОРА",
        company: "“ИЗИДА 13” ЕООД\n",
        phone: "0876 131 010, 056853357",
        name: "Артин Стамболян",
        email: "burgas@izida.bg"
    },
    {
        id: 14,
        city: "ШУМЕН",
        company: "“ДЕА” ООД\n",
        phone: "0899 134 051",
        name: "Нина Недялкова",
        email: "dea@izida.bg"
    },
    {
        id: 15,
        city: "ЯМБОЛ",
        company: "\"ИЗИДА\" ООД\n",
        phone: "0877 646 075",
        name: "Атанас Лазаров",
        email: "sliven@izida.bg"
    }
];