import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Home from "./pages/Home";
import Products from "./pages/Products";
import About from "./pages/About";
import Contacts from "./pages/Contacts";
import Oldtime from "./pages/products/Oldtime";
import Izida from "./pages/products/Izida";
import Haagendaz from "./pages/products/Haagendaz";
import Hotel from "./pages/Hotel";
import Test from "./pages/Test";
import Other from "./pages/Other";
import ScrollToTop from "./components/ScrollToTop";

function App() {
    return (
        <div className="App">
            <BrowserRouter basename="/">
                <ScrollToTop />
                <Routes>
                        <Route path={"/"} element={<Home/>}></Route>
                        <Route path={"/products"} element={<Products/>}></Route>
                        <Route path={"/products/izida"} element={<Izida/>}></Route>
                        <Route path={"/products/oldtime"} element={<Oldtime/>}></Route>
                        <Route path={"/products/haagen-daz"} element={<Haagendaz/>}></Route>
                        <Route path={"/about"} element={<About/>}></Route>
                        <Route path={"/other"} element={<Other/>}></Route>
                        <Route path={"/hotel"} element={<Hotel/>}></Route>
                        <Route path={"/contacts"} element={<Contacts/>}></Route>
                        <Route path={"/test"} element={<Test/>}></Route>
                </Routes>
            </BrowserRouter>

        </div>
    );
}

export default App;
