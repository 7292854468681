import React, {FC, useEffect, useState} from 'react';
import CustomButton from "./CustomButton";
import {SlideType} from "../types";

interface Props {
    text: string,
    title?: string,
    variant1?: string,
    variant2?: string,
    variant3?: string,
    variant4?: string,
    variant5?: string,
    variant6?: string,
    variant7?: string,
    variant8?: string,
    year?: string,
    align: string,
    button?: boolean,
    buttonText?: string,
    slides: SlideType[];
}

const RowTextSlider: FC<Props> = ({
                                      text,
                                      slides,
                                      title,
                                      variant1,
                                      variant2,
                                      variant3,
                                      variant4,
                                      variant5,
                                      variant6,
                                      variant7,
                                      variant8,
                                      year,
                                      button,
                                      buttonText,
                                      align
                                  }) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    function handlePreviousClick() {
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
    }

    function handleNextClick() {
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((currentSlide + 1) % slides.length);
        }, 3500);
        return () => clearInterval(interval);
    }, [currentSlide, slides]);

    return (
        <>
            <div className="mx-auto">
                <div className="flex flex-col md:grid md:grid-cols-1 md:grid-cols-2 ">
                    {align === 'left' && (
                        <div className="order-2 md:order-1 relative w-full md:h-[600px]">
                            <div className="hidden md:block absolute inset-0">
                                <img src={slides[currentSlide].image} alt="" className="md:object-cover md:w-full md:h-full"/>
                                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-800 text-white opacity-75">{slides[currentSlide].title}</div>
                            </div>
                            <div className="hidden md:block relative flex items-center p-8 ">

                                <div className="flex justify-between">
                                    <button onClick={handlePreviousClick}
                                            className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute left-0 top-0 mt-52 ml-8 text-white">
                                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor">
                                            <path fillRule="evenodd"
                                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                    <button onClick={handleNextClick}
                                            className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute right-0 top-0 mt-52 mr-8 text-white">
                                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor">
                                            <path fillRule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="block md:hidden  inset-0">
                                <img src={slides[currentSlide].image} alt="" className="md:object-cover md:w-full md:h-full"/>
                                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-800 text-white opacity-75">{slides[currentSlide].title}</div>
                            </div>
                        </div>
                    )}

                    <div className="order-1 md:order-1 lg:py-16 my-auto px-4">
                        <article className="pt-6 md:pt-0 space-y-4">
                            <p className="font-black text-2xl">{year}</p>

                            <p className="font-black text-4xl md:text-5xl uppercase">
                                {title}
                            </p>

                            <p className="text-xl text-justify">
                                {text}
                            </p>

                            <div className="block md:flex gap-3 font-bold text-justify">
                                <div>
                                    {variant1}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                                <div>
                                    {variant2}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                                <div>
                                    {variant3}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                            </div>


                            <div className="block md:flex gap-3 font-bold text-justify">
                                <div>
                                    {variant4}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                                <div>
                                    {variant5}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                                <div>
                                    {variant6}
                                </div>
                                <div className="block md:block">
                                    <br/>
                                </div>
                            </div>

                            <div className={button ? "" : "hidden"}><CustomButton to={"/"} text={buttonText}/></div>

                        </article>
                    </div>

                    {align === 'right' && (

                        <div className="order-2 md:order-2 relative w-full md:h-[600px]">
                            <div className="hidden md:block absolute inset-0">
                                <img src={slides[currentSlide].image} alt="" className="md:object-cover md:w-full md:h-full"/>
                                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-800 text-white opacity-75">{slides[currentSlide].title}</div>
                            </div>
                            <div className="hidden md:block relative flex items-center">
                                <div className="flex justify-between">
                                    <button onClick={handlePreviousClick}
                                            className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute left-0 top-0 mt-52 ml-8 text-white">
                                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor">
                                            <path fillRule="evenodd"
                                                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                    <button onClick={handleNextClick}
                                            className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute right-0 top-0 mt-52 mr-8 text-white">
                                        <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                             fill="currentColor">
                                            <path fillRule="evenodd"
                                                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                                  clipRule="evenodd"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div className="block md:hidden absolute inset-0">
                                <img src={slides[currentSlide].image} alt="" className="md:object-cover md:w-full md:h-full"/>
                                <div className="absolute bottom-0 left-0 right-0 p-4 bg-gray-800 text-white opacity-75">{slides[currentSlide].title}</div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

export default RowTextSlider;