import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import your translations
import enTranslations from './locales/en.json';
import bgTranslations from './locales/bg.json';

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: enTranslations,
        },
        bg: {
            translation: bgTranslations,
        },
    },
    lng: 'bg', // Set the default language
    fallbackLng: 'bg', // Set the fallback language
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;