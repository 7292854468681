import React from 'react';
import Navbar from "../layouts/Navbar";
import PrimaryContainer from "../layouts/PrimaryContainer";
import Footer from "../layouts/Footer";

import IzidaLogo from "../assets/logo_izida.png";
import IzidaOldTimeLogo from "../assets/oldtime-logo.png";
import HaagenDazLogo from "../assets/HaagenLogo.svg";
import OldTimeImage from "../assets/products/oldtime.jpg";
import IzidaImage from "../assets/products/izida.png";
import HaagenDazImage from "../assets/products/hagendaz.jpg"

import {Link} from "react-router-dom";

const Products = () => {
    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <div className="pt-32 md:pt-32">
                    <div className="grid md:grid-cols-3 lg:grid-cols-3">
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={OldTimeImage} alt="" className="w-full object-cover"/>
                            <Link to={"/products/oldtime"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-4xl md:text-5xl font-bold">
                                    <img src={IzidaOldTimeLogo} alt={"Изида Old-Time"}/>
                                </div>
                            </Link>
                        </div>
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={IzidaImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/izida"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-4xl md:text-5xl font-bold">
                                    <img src={IzidaLogo} alt={"Haagen-Daz Logo"}/>
                                </div>
                            </Link>
                        </div>
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={HaagenDazImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/haagen-daz"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-4xl md:text-5xl font-bold">
                                    <img src={HaagenDazLogo} alt={"Haagen-Daz Logo"}/>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Products;