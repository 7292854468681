import React from 'react';
import Navbar from "../layouts/Navbar";
import PrimaryContainer from "../layouts/PrimaryContainer";
import Footer from "../layouts/Footer";
import HeaderSlider from "../layouts/HeaderSlider";
import {HotelSlides} from "../data/constants";

import HotelImg1 from "../assets/hotel/about_1.jpg";
import HotelImg2 from "../assets/hotel/about_2.jpg";
import {useTranslation} from "react-i18next";

const Hotel = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <div className="pt-14 md:pt-32">
                    <HeaderSlider slides={HotelSlides}/>

                    <div className="p-3 md:p-20">
                        <div className="text-center text-4xl font-bold pb-2 ">{t("hotel_title")}</div>
                        <hr/>
                        <div className="pt-8">
                            <div className="pb-3"><b>{t("hotel_text_bold")}</b> {t("hotel_text1")}
                            </div>

                            <div className="pb-3">{t("hotel_text2")} <b>{t("hotel_text2_bold")}</b> {t("hotel_text4")} <b>{t("hotel_text3_bold")}.</b> {t("hotel_text5")}
                            </div>

                            <div>{t("hotel_text6")}
                            </div>
                        </div>

                        <div className="md:flex p-5 ml-20 justify-center hidden">
                            <div>
                                <img src={HotelImg1} className="w-2/3" alt=""/>
                            </div>
                            <div>
                                <img src={HotelImg2}  className="w-2/3" alt=""/>
                            </div>
                        </div>

                        <div className="md:hidden block p-3 ">
                            <img src={HotelImg1} alt="" className="pb-2" />
                            <img src={HotelImg2} alt="" />
                        </div>

                        <div>

                            <div className="pb-5">{t("hotel_text7")}
                            </div>

                            <div className="pb-5">{t("hotel_text8")}
                            </div>

                            <div className="pb-5">{t("hotel_text9")}
                            </div>

                        </div>
                    </div>

                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Hotel;