import React, {useState} from 'react';
import {Map, Marker, Overlay} from "pigeon-maps"
import {markers} from "../data/constants";
import {distributors} from "../data/distributors";
import CloseButtonIcon from '../assets/close-icon.png'

import MarkerImage from "../assets/logo_izida.png";

const MapPosition = () => {

    const [selectedDiv, setSelectedDiv] = useState<{ id: number, city: string, company: string, phone: string, name: string, email: string, anchor: [number, number] } | null>(null);

    const handleDivClick = (div: { id: number, city: string, company: string, phone: string, name: string, email: string, anchor: [number, number] }) => {
        setSelectedDiv(div);
    }

    const handleCloseButton = () => {
        setSelectedDiv(null);
    }

    return (
        <div className="relative overflow-hidden w-full h-full p-2">
            <Map defaultCenter={[42.6493, 25.7888]}
                 defaultZoom={7}
                 mouseEvents={false}
                 touchEvents={false}
            >
                {markers.map((item, index) =>
                    <Marker
                        key={distributors[index].id}
                        onClick={() => handleDivClick({...distributors[index], anchor: item.anchor})}
                        anchor={item.anchor}
                    >
                        <img style={{ pointerEvents: 'auto' }}  src={MarkerImage} width={50} height={50} alt="Custom marker" />
                    </Marker>
                )}
                {selectedDiv &&
                    <Overlay anchor={selectedDiv.anchor}>
                        <div
                            className="bg-white rounded-lg shadow-xl py-2 px-8 text-gray-800">
                            <div className="" onClick={handleCloseButton}><img
                                src={CloseButtonIcon} alt={"Close Pop-up"}/></div>
                            <p className="font-black uppercase">{selectedDiv.city}</p>
                            <p>{selectedDiv.company}</p>
                            <p>{selectedDiv.name}</p>
                            <p>{selectedDiv.phone}</p>
                            <p>{selectedDiv.email}</p>
                        </div>
                    </Overlay>
                }
            </Map>
        </div>
    );
};

export default MapPosition;