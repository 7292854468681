import React from 'react';
import Navbar from "../layouts/Navbar";
import PrimaryContainer from "../layouts/PrimaryContainer";
import Footer from "../layouts/Footer";
import MapPosition from "../components/MapPosition";
import {distributors} from "../data/distributors";
import ImageButtonCTA from "../components/ImageButtonCTA";
import ParkHotelIzida from "../assets/cta-hotel-izida.jpeg";
import {useTranslation} from "react-i18next";
import MapMobile from "../assets/map-mobile.png";

const Contacts = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <div className="pt-24 md:pt-24">
                    <div className="mx-auto md:pt-10">
                        <div className="flex flex-col md:grid md:grid-cols-1 md:grid-cols-2 ">
                            <MapPosition/>
                            <div className="px-2">
                                <div className="text-2xl uppercase font-black pb-4">{t("distributors")}</div>
                                <div className="grid grid-cols-2 md:grid-cols-4 text-black gap-1">
                                    {distributors.map((item, index) =>
                                        <div className="pb-4">
                                            <div className="md:text-lg uppercase font-black ">{item.city}</div>
                                            <div className="">{item.company}</div>
                                            <div className="">{item.name}</div>
                                            <div className="">{item.phone}</div>
                                            <div className="">{item.email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <img className="md:hidden block w-full h-full" src={MapMobile} alt=""/>

                        </div>
                    </div>
                    <div className="pt-3 pb-3">
                        <ImageButtonCTA
                            image={ParkHotelIzida}
                            buttonText={t("cta_button_text")}
                            buttonUrl="/hotel"
                        />
                    </div>
                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Contacts;