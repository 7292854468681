import React from 'react';
import Navbar from "../../layouts/Navbar";
import PrimaryContainer from "../../layouts/PrimaryContainer";
import Footer from "../../layouts/Footer";
import RowTextSlider from "../../components/RowTextSlider";
import {bombastic, bombasticEN} from "../../data/products";
import {bombasticSlides, excellenceSlides, izidaSlides, oldtimeSlides} from "../../data/constants";
import OldTimeImage from "../../assets/products/oldtime.jpg";
import {Link} from "react-router-dom";
import OldTimeLogo from "../../assets/oldtime-logo.png";
import HaagenDazImage from "../../assets/products/hagendaz.jpg";
import HaagenDazLogo from "../../assets/HaagenLogo.svg";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

const Izida = () => {
    const { t } = useTranslation();
    const bombasticData = i18n.language === 'en' ? bombasticEN[0] : bombastic[0];
    const bombasticData1 = i18n.language === 'en' ? bombasticEN[1] : bombastic[1];
    const bombasticData2 = i18n.language === 'en' ? bombasticEN[2] : bombastic[2];

    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <div>
                    <div className="pt-24">
                        <div className={"pt-1"}>
                            <RowTextSlider
                                title={bombasticData.title}
                                text={bombasticData.text}
                                variant1={bombasticData.variant1}
                                variant2={bombasticData.variant2}
                                variant3={bombasticData.variant3}
                                slides={izidaSlides} align="left"
                            />
                        </div>
                        <div className="py-6 md:py-0">
                            <RowTextSlider
                                title={bombasticData1.title}
                                text={bombasticData1.text}
                                variant1={bombasticData1.variant1}
                                variant2={bombasticData1.variant2}
                                slides={bombasticSlides} align="right"
                            />
                        </div>
                        <div className="py-6 md:py-0">
                            <RowTextSlider
                                title={bombasticData2.title}
                                text={bombasticData2.text}
                                variant1={bombasticData2.variant1}
                                slides={excellenceSlides} align="left"
                            />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 lg:grid-cols-2">
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={OldTimeImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/oldtime"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-5xl font-bold">
                                    <img src={OldTimeLogo} alt={"Old time Logo"}/>
                                </div>
                            </Link>
                        </div>
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={HaagenDazImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/haagen-daz"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-5xl font-bold">
                                    <img src={HaagenDazLogo} alt={"Haagen-Daz Logo"}/>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Izida;