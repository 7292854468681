import React from 'react';
import Navbar from "../layouts/Navbar";
import PrimaryContainer from "../layouts/PrimaryContainer";
import Footer from "../layouts/Footer";
import RowImageText from "../components/RowImageText";
import ImageButtonCTA from "../components/ImageButtonCTA";
import ParkHotelIzida from "../assets/cta-hotel-izida.jpeg";
import GalleryBox from "../components/GalleryBox";

import FirstRow from "../assets/history/1-1991.jpg";
import SecondRow from "../assets/history/1997.jpg";
import ThirdRow from "../assets/history/3-2005.jpg";
import ThirdRowSecond from "../assets/history/3-2005.jpg";
import FourthRow from "../assets/history/5-2013.png";
import FifthRow from "../assets/history/6-2014.jpg";
import FifthRowSecond from "../assets/history/7 - 2014.jpg";
import SixthRow from "../assets/history/izida.jpg";
import SixthRowSecond from "../assets/history/9 - 2016.png";
import HotelIzida2009 from "../assets/history/2009.jpg";
import ZelenaEnergiq20 from "../assets/history/zelena-energiq.jpg";
import BusesImg from "../assets/history/buses.jpg";

import Certificate1 from "../assets/history/certificates/2014_small.jpg";
import Certificate2 from "../assets/history/certificates/2015_small.jpg";
import Certificate3 from "../assets/history/certificates/2016_small.jpg";
import {useTranslation} from "react-i18next";

const About = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <>
                    <div className="pt-12">
                        <div className="pt-20">
                            <div>
                                <RowImageText
                                    year={"91`"}
                                    title={t("about_title1")}
                                    text={t("about_text1")}
                                    buttonHref="/"
                                    image={FirstRow} align="right" removeParallax/>
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"97`"}
                                    title={t("about_title2")}
                                    text={t("about_text2")}
                                    buttonHref="/"
                                    image={SecondRow} align="left" removeParallax/>
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"05`"}
                                    title={t("about_title3")}
                                    text={t("about_text3")}
                                    buttonHref="/"
                                    image={ThirdRowSecond} align="right" removeParallax/>
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"09`"}
                                    title={t("about_title4")}
                                    text={t("about_text4")}
                                    buttonHref="/"
                                    image={HotelIzida2009} align="left" removeParallax/>
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"14`"}
                                    title={t("about_title5")}
                                    text={t("about_text5")}
                                    buttonHref="/"
                                    image={FifthRow} align="right" removeParallax/>

                                <div className="flex justify-center content-center">
                                    <a href={Certificate1} target="_blank"><img src={Certificate1} alt={""}/></a>
                                    <a href={Certificate2} target="_blank"><img src={Certificate2} alt={""}/></a>
                                    <a href={Certificate3} target="_blank"><img src={Certificate3} alt={""}/></a>
                                </div>
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"16`"}
                                    title={t("about_title6")}
                                    text={t("about_text6")}
                                    buttonHref="/"
                                    image={SixthRow}
                                    align="left"
                                    removeParallax
                                />
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"20`"}
                                    image={ZelenaEnergiq20}
                                    align="right"
                                    button
                                    title={t("about_title7")}
                                    text={t("about_text7")}
                                    buttonHref="/other"
                                    buttonText={t("about_button7")}
                                    removeParallax
                                />
                            </div>
                            <div className="py-6 md:py-0">
                                <RowImageText
                                    year={"21`"}
                                    title={t("about_title8")}
                                    text={t("about_text8")}
                                    image={BusesImg}
                                    align="left"
                                    buttonHref="/"
                                    removeParallax
                                />
                            </div>
                            <div className="pt-3 pb-3">
                                <ImageButtonCTA
                                    image={ParkHotelIzida}
                                    buttonText={t("cta_button_text")}
                                    buttonUrl="/hotel"
                                />
                            </div>
                        </div>

                        <Footer/>
                    </div>
                </>
            </PrimaryContainer>
        </div>
    );
};

export default About;