import React, {FC} from 'react';
import {Link} from "react-router-dom";

interface Props {
    to: string,
    text?: string,

}

const CustomButton: FC<Props> = ({
                                     to,
                                     text
                                 }) => {
    return (
        <div>
            <Link
                to={to}
            >
                <span
                    className="inline-block border-2 border-current px-8 py-3 text-sm font-bold uppercase tracking-widest text-black bg-yellow-900 hover:bg-red-900"
                >
                    <div className="text-white">
                     {text}
                    </div>
              </span>
            </Link>
        </div>
    );
};

export default CustomButton;