import React, {FC, useEffect, useState} from 'react';
import {SlideType} from "../types";

interface Props {
    slides: SlideType[];
    withoutSliderButtons?: boolean; // new prop to hide slider buttons

}

const HeaderSlider: FC<Props> = ({slides, withoutSliderButtons}) => {

    const [currentSlide, setCurrentSlide] = useState(0);

    function handlePreviousClick() {
        setCurrentSlide(currentSlide === 0 ? slides.length - 1 : currentSlide - 1);
    }

    function handleNextClick() {
        setCurrentSlide(currentSlide === slides.length - 1 ? 0 : currentSlide + 1);
    }

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentSlide((currentSlide + 1) % slides.length);
        }, 3500);
        return () => clearInterval(interval);
    }, [currentSlide, slides]);

    return (
        <div>
            <div className="relative w-full h-full md:pb-12">
                <div className="absolute inset-0">
                    <img src={slides[currentSlide].image} alt="" className="object-cover w-full h-full"/>
                </div>
                <div className="relative flex items-center p-8">
                    <div className="w-full text-center mx-auto py-44 text-white bg-white bg-opacity-5 ">
                        <p className="text-4xl md:text-5xl font-bold mb-4 uppercase text-brown">{slides[currentSlide]?.title}</p>
                        {/*<CustomButton to={"/"} text={"Discover our milkshakes"} />*/}
                    </div>
                    {!withoutSliderButtons && ( // check the prop and render buttons accordingly
                        <div className="flex justify-between">
                        <button onClick={handlePreviousClick}
                                className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute left-0 top-0 mt-52 ml-8 text-white">
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                        <button onClick={handleNextClick}
                                className="px-4 py-5 rounded-full bg-opacity-50 hover:bg-gray-500 hover:bg-opacity-20 hover:text-black bg-gray-200 absolute right-0 top-0 mt-52 mr-8 text-white">
                            <svg className="w-10 h-10" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"
                                 fill="currentColor">
                                <path fillRule="evenodd"
                                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                      clipRule="evenodd"/>
                            </svg>
                        </button>
                    </div>
                        )}
                </div>
            </div>
        </div>
    );
};

export default HeaderSlider;