import React from 'react';
import {oldtimeSlides} from "../../data/constants";
import {bandiSlides} from "../../data/constants";
import Navbar from "../../layouts/Navbar";
import PrimaryContainer from "../../layouts/PrimaryContainer";
import Footer from "../../layouts/Footer";
import {oldtime, oldtimeEN} from "../../data/products";
import HaagenDazImage from "../../assets/products/hagendaz.jpg";
import {Link} from "react-router-dom";
import IzidaLogo from "../../assets/logo_izida.png";
import RowTextSlider from "../../components/RowTextSlider";
import IzidaImage from "../../assets/products/izida.png";
import HaagenDazLogo from "../../assets/HaagenLogo.svg";
import {useTranslation} from "react-i18next";
import i18n from "../../i18n";

const Oldtime = () => {

    const { t } = useTranslation();
    const oldtimeData = i18n.language === 'en' ? oldtimeEN[0] : oldtime[0];
    const oldtimeData1 = i18n.language === 'en' ? oldtimeEN[1] : oldtime[1];

    return (
        <div>
            <Navbar/>
            {/*<div className="pt-32"><HeaderSlider slides={oldtimeSlides}/></div>*/}
            <PrimaryContainer>
                <div>
                    <div className="pt-24">
                        <div className={"pt-1"}>
                            <RowTextSlider
                                title={oldtimeData.title}
                                text={oldtimeData.text}
                                variant1={oldtimeData.variant1}
                                variant4={oldtimeData.variant3}
                                variant6={oldtimeData.variant2}
                                slides={oldtimeSlides} align="left"
                            />
                        </div>
                        <div className="py-6 md:py-0">
                            <RowTextSlider
                                title={oldtimeData1.title}
                                text={oldtimeData1.text}
                                slides={bandiSlides} align="right"
                            />
                        </div>
                    </div>

                    <div className="grid md:grid-cols-2 lg:grid-cols-2">
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={IzidaImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/izida"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-5xl font-bold">
                                    <img src={IzidaLogo} alt={"Haagen-Daz Logo"}/>
                                </div>
                            </Link>
                        </div>
                        <div className="relative m-1 transition-opacity duration-300 md:opacity-70 hover:opacity-100">
                            <img src={HaagenDazImage} alt="Haagen-Daz" className="w-full object-cover"/>
                            <Link to={"/products/haagen-daz"}>
                                <div
                                    className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center text-white text-5xl font-bold">
                                    <img src={HaagenDazLogo} alt={"Haagen-Daz Logo"}/>
                                </div>
                            </Link>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Oldtime;