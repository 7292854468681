import React from 'react';
import HeaderSlider from "../layouts/HeaderSlider";
import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import RowImageText from "../components/RowImageText";
import PrimaryContainer from "../layouts/PrimaryContainer";
import ImageButtonCTA from "../components/ImageButtonCTA";

import ParkHotelIzida from "../assets/cta-hotel-izida.jpeg";

import Parallax1 from "../assets/home-parallax/izida-snap.jpg";
import Parallax2 from "../assets/home-parallax/solarni-paneli.jpg";
import Parallax3 from "../assets/home-parallax/zavod.jpg";
import OldTimeImage from "../assets/products/oldtime.jpg";

import {slides} from "../data/constants";
import {Link} from "react-router-dom";

import { useTranslation } from "react-i18next";

const Home = () => {
    const { t } = useTranslation();

    return (
        <div className="">
            <Navbar transparent/>
            <PrimaryContainer>
                <div>
                    <HeaderSlider slides={slides}/>

                    <div>
                        <RowImageText
                            title={t("home_title1")}
                            text={t("home_about1")}
                            image={Parallax1} button buttonHref="/products" buttonText={t("home_button1")} align="right"/>
                    </div>

                    <div className="py-6 md:py-0">
                        <RowImageText
                            title={t("home_title2")}
                            text={t("home_about2")}
                            image={Parallax2} align="left" button buttonHref="/about" buttonText={t("learn_more")}/>
                    </div>

                    <div className="py-6 md:py-0">
                        <RowImageText
                            title={t("home_title3")}
                            text={t("home_about3")}
                            secondTitle={t("home_subtitle3")}
                            secondText={
                                <>
                                    {t("home_subtext3")}
                                </>}
                            image={OldTimeImage} align="right" button buttonHref="/products/oldtime" buttonText={t("learn_more")}/>
                    </div>

                    <div className="pt-3 pb-3">
                        <ImageButtonCTA
                            image={ParkHotelIzida}
                            buttonText={t("cta_button_text")}
                            buttonUrl="/hotel"
                        />
                    </div>
                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Home;