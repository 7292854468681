import React from 'react';
import Navbar from "../layouts/Navbar";
import PrimaryContainer from "../layouts/PrimaryContainer";
import Footer from "../layouts/Footer";
import HeaderSlider from "../layouts/HeaderSlider";
import {HotelSlides} from "../data/constants";
import ImageButtonCTA from "../components/ImageButtonCTA";
import ParkHotelIzida from "../assets/cta-hotel-izida.jpeg";

import Fotovoltaichni from "../assets/history/zelena-energiq.jpg";
import IzidaCup from "../assets/izidacup.jpg";
import RowImageText from "../components/RowImageText";
import {useTranslation} from "react-i18next";

const Other = () => {
    const { t } = useTranslation();

    return (
        <div>
            <Navbar/>
            <PrimaryContainer>
                <div className="pt-14 md:pt-32">
                    <HeaderSlider slides={HotelSlides} withoutSliderButtons/>

                    <div className="pt-20">
                        <div>
                            <RowImageText
                                title={t("other_title1")}
                                buttonHref="/"
                                text={t("other_text1")}
                                image={IzidaCup} align="right"/>
                        </div>
                        <div className="py-6 md:py-0">
                            <RowImageText
                                title={t("other_title2")}
                                buttonHref="/"
                                text={t("other_text2")}
                                image={Fotovoltaichni} align="left"/>
                        </div>
                    </div>

                    <div className="pt-3 pb-3">
                        <ImageButtonCTA
                            image={ParkHotelIzida}
                            buttonText={t("cta_button_text")}
                            buttonUrl="/hotel"
                        />
                    </div>
                    <Footer/>
                </div>
            </PrimaryContainer>
        </div>
    );
};

export default Other;