import Slider2 from "../assets/slider/omp/slider1.png";
import Slider1 from "../assets/slider/omp/slider.png";
import ParkHotelIzida from "../assets/cta-hotel-izida.jpeg";

import OldTimeSlide1 from "../assets/products/oldtime/IZIDA_Product-Picture_Old-Time-750ml_Vanilla_1000x1000px.jpg";
import OldTimeSlide2 from "../assets/products/oldtime/IZIDA_Product-Picture_Old-Time-750ml_Coffee_1000x1000px.jpg";
import OldTimeSlide3 from "../assets/products/oldtime/IZIDA_Product-Picture_Old-Time-750ml_Cocoa_1000x1000px.jpg";

import BandiSlides1 from "../assets/products/oldtime/bandi/IZIDA_Product-Picture_Bandi-Cocoa-nuts_1000x1000px.jpg";
import BandiSlides2 from "../assets/products/oldtime/bandi/IZIDA_Product-Picture_Bandi-Sandwitch-mixed_1000x1000px.jpg";
import BandiSlides3 from "../assets/products/oldtime/bandi/IZIDA_Product-Picture_Bandi-Vanilla-nuts_1000x1000px.jpg";

import BombasticStick1 from "../assets/products/Bombastic/stick/IZIDA_Product-Picture_Bombastic-Party-Stick_Cocoa_1000x1000px.jpg";
import BombasticStick2 from "../assets/products/Bombastic/stick/IZIDA_Product-Picture_Bombastic-Party-Stick_Vanilla_1000x1000px.jpg";
import BombasticCone1 from "../assets/products/Bombastic/cone/IZIDA_Product-Picture_Bombastic-Party-Cone_Cocoa_1000x1000px.jpg";
import BombasticCone2 from "../assets/products/Bombastic/cone/IZIDA_Product-Picture_Bombastic-Party-Cone_Melon_1000x1000px.jpg";
import BombasticCone3 from "../assets/products/Bombastic/cone/IZIDA_Product-Picture_Bombastic-Party-Cone_Strawberry_1000x1000px.jpg";
import BombasticCone4 from "../assets/products/Bombastic/cone/IZIDA_Product-Picture_Bombastic-Party-Cone_Vanilla_1000x1000px.jpg";

import izidaSlides1 from "../assets/products/Izida/IZIDA_Product-Picture_Izida-200-400ml_Cocoa_1000x1000px.jpg";
import izidaSlides2 from "../assets/products/Izida/IZIDA_Product-Picture_Izida-200-400ml_Lemon_1000x1000px.jpg";
import izidaSlides3 from "../assets/products/Izida/IZIDA_Product-Picture_Izida-200-400ml_Strawberry_1000x1000px.jpg";
import izidaSlides4 from "../assets/products/Izida/IZIDA_Product-Picture_Izida-200-400ml_Vanilla_1000x1000px.jpg";
import izidaSlides5 from "../assets/products/Izida/IZIDA_Product-Picture_Izida-200-400ml_Violet_1000x1000px.jpg";

import excellenceSlides1 from "../assets/products/Excellence/IZIDA_Product-Picture_Excellence-800ml_Caramel_1000x1000px.jpg";
import excellenceSlides2 from "../assets/products/Excellence/IZIDA_Product-Picture_Excellence-800ml_Cherry_1000x1000px.jpg";
import excellenceSlides3 from "../assets/products/Excellence/IZIDA_Product-Picture_Excellence-800ml_Cocoa_1000x1000px.jpg";
import excellenceSlides4 from "../assets/products/Excellence/IZIDA_Product-Picture_Excellence-800ml_Fig_1000x1000px.jpg";
import excellenceSlides5 from "../assets/products/Excellence/IZIDA_Product-Picture_Excellence-800ml_Mango_1000x1000px.jpg";

import HaagendazsSlides1 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Macadamia-nuts_1000x1000px.jpg";
import HaagendazsSlides2 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Choco-almond_1000x1000px.jpg";
import HaagendazsSlides3 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Caramel-almond_1000x1000px.jpg";
import HaagendazsSlides4 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Mango-strawberry_1000x1000px.jpg";
import HaagendazsSlides5 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_CBelgian-chocolate_1000x1000px.jpg";
import HaagendazsSlides6 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Macadamia-nut-brittle_1000x1000px.jpg";
import HaagendazsSlides7 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Cookies-cream_1000x1000px.jpg";
import HaagendazsSlides8 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Pralines-cream_1000x1000px.jpg";
import HaagendazsSlides9 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Mango-raspberry_1000x1000px.jpg";
import HaagendazsSlides10 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Strawberry-cheesecake_1000x1000px.jpg";


import {anchorType} from "../types";

export const slides = [
    {
        title: '',
        image: Slider2
    },
    {
        title: '',
        image: Slider1
    },
    {
        title: '',
        image: Slider2
    }
];

export const HotelSlides = [
    {
        image: ParkHotelIzida,
    }
]


export const izidaSlides = [
    {
        title: 'Солов вкус  какао в опаковка от 200 и 400 мл\n\n',
        image: izidaSlides1
    },
    {
        title: 'Солов вкус  лимон в опаковка от 200 и 400 мл\n\n',
        image: izidaSlides2
    },
    {
        title: 'Солов вкус ягода в опаковка от 200 и 400 мл\n\n',
        image: izidaSlides3
    },
    {
        title: 'Солов вкус ванилия в опаковка от 200 и 400 мл\n\n',
        image: izidaSlides4
    },
    {
        title: 'Солов вкус виолетка в опаковка от 200 и 400 мл\n\n',
        image: izidaSlides5
    }
];

export const excellenceSlides = [
    {
        title: 'Солов вкус карамел в опаковка от 800мл. \n\n',
        image: excellenceSlides1
    },
    {
        title: 'Солов вкус череша в опаковка от 800мл. \n\n',
        image: excellenceSlides2
    },
    {
        title: 'Солов вкус какао в опаковка от 800мл. \n\n',
        image: excellenceSlides3
    },
    {
        title: 'Солов вкус смокиня в опаковка от 800мл. \n\n',
        image: excellenceSlides4
    },
    {
        title: 'Солов вкус манго в опаковка от 800мл. \n\n',
        image: excellenceSlides5
    }
];


export const oldtimeSlides = [
    {
        title: 'Солов вкус ванилия в опаковка от 1000 мл\n',
        image: OldTimeSlide1
    },
    {
        title: 'Солов вкус кафе в опаковка от 1000 мл\n',
        image: OldTimeSlide2
    },
    {
        title: 'Солов вкус какао в опаковка от 1000 мл\n',
        image: OldTimeSlide3
    }
];

export const bombasticSlides = [
    {
        title: 'Сладолед Какао на клечка',
        image: BombasticStick1
    },
    {
        title: 'Сладолед Ванилия на клечка',
        image: BombasticStick2
    },
    {
        title: 'Сладолед Какао във вафлена фуния',
        image: BombasticCone1
    },
    {
        title: 'Сладолед Пъпеш във вафлена фуния',
        image: BombasticCone2
    },
    {
        title: 'Сладолед Ягода във вафлена фуния',
        image: BombasticCone3
    },
    {
        title: 'Сладолед Ванилия във вафлена фуния',
        image: BombasticCone4
    }
];
//
// import HaagendazsSlides1 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Macadamia-nuts_1000x1000px.jpg";
// import HaagendazsSlides2 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Choco-almond_1000x1000px.jpg";
// import HaagendazsSlides3 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Caramel-almond_1000x1000px.jpg";
// import HaagendazsSlides4 from "../assets/products/haagendaz/stick/IZIDA_Product-Picture_Hageen-Dazs-Stick_Mango-strawberry_1000x1000px.jpg";
// import HaagendazsSlides5 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_CBelgian-chocolate_1000x1000px.jpg";
// import HaagendazsSlides6 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Macadamia-nut-brittle_1000x1000px.jpg";
// import HaagendazsSlides7 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Cookies-cream_1000x1000px.jpg";
// import HaagendazsSlides8 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Pralines-cream_1000x1000px.jpg";
// import HaagendazsSlides9 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Mango-raspberry_1000x1000px.jpg";
// import HaagendazsSlides10 from "../assets/products/haagendaz/cup/IZIDA_Product-Picture_Hageen-Dazs-Cup_Strawberry-cheesecake_1000x1000px.jpg";


export const haagendazsStick = [
    {
        title: 'Сладолед на клечка MACADAMIA NUT BRITTLE',
        image: HaagendazsSlides1
    },
    {
        title: 'Сладолед на клечка CHOCOLATE CHOC ALMOND',
        image: HaagendazsSlides2
    },
    {
        title: 'Сладолед на клечка CHOCOLATE CHOC ALMOND',
        image: HaagendazsSlides3
    },
    {
        title: 'Сладолед на клечка MANGO & RASPBERRY',
        image: HaagendazsSlides4
    },
];

export const haagendazsCup = [
    {
        title: 'Сладолед в кутия DUO BELGIAN CHOCOLATE',
        image: HaagendazsSlides5
    },
    {
        title: 'Сладолед в кутия МACADAMIA NUT BRITTLE',
        image: HaagendazsSlides6
    },
    {
        title: 'Сладолед в кутия COOKIES&CREAM',
        image: HaagendazsSlides7
    },
    {
        title: 'Сладолед в кутия COOKIES&CREAM',
        image: HaagendazsSlides8
    },
    {
        title: 'Сладолед в кутия MANGO & RASPBERRY',
        image: HaagendazsSlides9
    },
    {
        title: 'Сладолед в кутия STRAWBERRY CHEESECAKE',
        image: HaagendazsSlides10
    }
];


export const bandiSlides = [
    {
        title: 'Какаов сладолед\n' +
            'с млечно-шоколадова глазура\n' +
            'с ядки',
        image: BandiSlides1
    },
    {
        title: 'Ванилов сладолед или\n' +
            'комбинация между ванилов и\n' +
            'какаов сладолед между\n' +
            'какаови бисквитки  ',
        image: BandiSlides2
    },
    {
        title: 'Ванилов сладолед\n' +
            'с млечно-шоколадова глазура\n' +
            'с ядки',
        image: BandiSlides3
    }
];

export const markers : anchorType[] = [
    {
        anchor: [42.5029, 27.4076],
    },
    {
        anchor: [42.0114, 23.0915],
    },
    {
        anchor: [43.2071, 27.9115],
    },
    {
        anchor: [43.0813, 25.6355],
    },
    {
        anchor: [43.572, 27.8313],
    },
    {
        anchor: [43.4079, 24.6219],
    },
    {
        anchor: [42.1426, 24.7517],
    },
    {
        anchor: [43.8398, 25.9689],
    },
    {
        anchor: [41.5644, 23.2789],
    },
    {
        anchor: [44.1115, 27.2594],
    },
    {
        anchor: [44.1033, 27.2457],
    },
    {
        anchor: [42.6866, 23.3238],
    },
    {
        anchor: [42.4258, 25.6334],
    },
    {
        anchor: [43.2704, 26.9269],
    },
    {
        anchor: [42.4833, 26.5111],
    }
]