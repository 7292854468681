import React from 'react';
import CustomButton from "./CustomButton";

const ImageButtonCTA = (props: { image: any; buttonText: any; buttonUrl: any; }) => {
    const { image, buttonText, buttonUrl } = props;

    return (
        <div className="relative bg-no-repeat bg-center bg-cover p-48" style={{ backgroundImage: `url(${image})` }}>
            <div className="absolute inset-0 flex items-center justify-center min-content">
                <CustomButton to={buttonUrl} text={buttonText} />
            </div>
        </div>


    );
}

export default ImageButtonCTA;